<template>
 <v-container
    id="settings"
    fluid
    tag="section"
  >
  <router-view />
  <!-- <SettingsCompanyCard />
  <SettingsDataSources />
  <SettingsCarriers />
  <SettingsPrinterCard />
  <SettingsUserCard /> -->
</v-container>

</template>
<script>
// import SettingsCompanyCard from '@/components/SettingsCompanyCard'
// import SettingsDataSources from '@/components/SettingsDataSources'
// import SettingsCarriers from '@/components/SettingsCarriers'
// import SettingsPrinterCard from '@/components/SettingsPrinterCard'
// import SettingsUserCard from '@/components/SettingsUserCard'

export default {
  components: {
    // SettingsCompanyCard,
    // SettingsDataSources,
    // SettingsCarriers,
    // SettingsPrinterCard,
    // SettingsUserCard,
  },
  computed: {

  },
  
  
  filters: {
    // CurrencyFilter,
    // DateFilter,
  },
  data() {
    return {
      loading: false,
      loading_connection: false,
      code: ''
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
    
</style>